import React, { lazy, Suspense } from "react";
import Icon from "@mui/material/Icon";
import { Box, CircularProgress } from "@mui/material";
const Dashboard = lazy(() => import("layouts/dashboard"));
const Tables = lazy(() => import("layouts/tables"));
const Tables2 = lazy(() => import("layouts/tablesclient"));
const Tables3 = lazy(() => import("layouts/rm"));
const Billing = lazy(() => import("layouts/billing"));
const ViewClientDetails = lazy(() => import("layouts/tablesclient/Details"));
const Profile = lazy(() => import("layouts/profile"));
const SignUp = lazy(() => import("layouts/authentication/sign-up"));
const Mailer = lazy(()=> import ("layouts/mailer"));
import { useAuth } from "layouts/authentication/AuthContext";
import ProtectedRoute from "layouts/authentication/ProtectedRoute";
import { Mail } from "@mui/icons-material";
const routes = () => {
  const { userRole } = useAuth();
 
  return [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Leads",
      key: "leads",
      icon: <Icon fontSize="small">contacts</Icon>,
      route: "/leads",
      component: (
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Tables />
        </Suspense>
      ),
    },
    {
      type: "collapse",
      name: "Clients",
      key: "clients",
      icon: <Icon fontSize="small">contacts</Icon>,
      route: "/clients",
      component: (
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Tables2 />
        </Suspense>
      ),
      collapse: [
        {
          route: "/clients",
          name: "Clients",
          key: "clients",
          component: (
            <Suspense
              fallback={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Tables2 />
            </Suspense>
          ),
        },
        {
          route: "/:id",
          name: "Clients",
          key: "clients",
          component: (
            <Suspense
              fallback={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                >
                  <CircularProgress />
                </Box>
              }
            >
              <ViewClientDetails />
            </Suspense>
          ),
        },
      ],
    },
    userRole === "Admin" && {
      type: "collapse",
      name: "RM",
      key: "RMData",
      icon: <Icon fontSize="small">support_agent</Icon>,
      route: "/RMData",
      component: (
        <ProtectedRoute allowedRoles={["Admin"]}>
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Box>
            }
          >
            <Tables3 />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      type: "collapse",
      name: "Calendar",
      key: "calendar",
      icon: <Icon fontSize="small">calendar_month</Icon>,
      route: "/calendar",
      component: (
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Billing />
        </Suspense>
      ),
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: (
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Profile />
        </Suspense>
      ),
    },
    userRole === "Admin" && {
      type: "collapse",
      name: "Send Greetings",
      key: "send-greetings",
      icon: <Icon fontSize="small">mail</Icon>,
      route: "send-greetings",
      component: (
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Mailer />
        </Suspense>
      ),
    },
    // userRole === "Admin" && {
    //   type: "collapse",
    //   name: "Add User",
    //   key: "add-user",
    //   icon: <Icon fontSize="small">assignment</Icon>,
    //   route: "add-user",
    //   component: (
    //     <Suspense
    //       fallback={
    //         <Box
    //           display="flex"
    //           justifyContent="center"
    //           alignItems="center"
    //           minHeight="100vh"
    //         >
    //           <CircularProgress />
    //         </Box>
    //       }
    //     >
    //       <SignUp />
    //     </Suspense>
    //   ),
    // },
  ];
};

export default routes;
